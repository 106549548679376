@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

select {
  background-color: #686868;
  padding: 5px 0 5px 5px;
  border-style: inset;
  border: solid 1px #999966;
  height: 31px;
  width: 50px;
  font-size: 15px;
  color: #ffffff;
}

img {
  display: initial;
}

input {
  background-color: #686868;
  font-size: 9pt;
  padding-right: 3;
  padding-left: 3;
  padding-top: 0;
  padding-bottom: 0;
  border-style: inset;
  border-color: #999966;
  border-width: 1pt;
  color: #ffffff;
  text-align: center;
}

#container {
  width: 1100px;
  height: 1000px;
  margin: 0 auto;
  display: block;
  background: #f8f8f8;
}

#loginContainer {
  width: 1100px;
  height: 1000px;
  margin: 0 auto;
  display: block;
  background: #f8f8f8;
}

.wlineblue {
  background-color: #344273;
}

.subtitle {
  background-color: #222222;
  color: #ffffff;
  font-weight: bold;
  font-size: 12px;
  padding: 5px 0;
}

.top_entry, .bottom_entry {
  padding: 0 0 10px 0;
  height: 27px;
}

.edit {
  width: 95px;
}

.edit img, .top_entry img {
  margin: 0 5px;
  width: 16px;
  height: 16px;
}

.edit_active, .save_active, .add_active, .delete_active {
  cursor: pointer;
}

.paircell {
  color: #000000;
  font-size: 10pt;
  padding: 4pt;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #000000;
}

.loginCell {
  color: #000000;
  font-size: 10pt;
  padding: 4pt;
  border-bottom-style: hidden;
  border-bottom-width: 1px;
}

.login {
  margin-left: 10px;
}

.password {
  margin-left: 33px;
}

#loginTable {
  margin-top: 20px;
}

.loginBtn {
  cursor: pointer;
  background-color: #FFFFFF;
  color: #000000;
  font-size: 12pt;
  border-radius: 5px;
  width: 100px;
}

.arenaDate {
  width: 190px;
  padding-left: 466px;
}

.logo_netsign {
  width: 256px;
  height: 57px;
  margin-top: 90px;
}

.header-login {
  margin-top: 20px;
}

.loginInput {
  background-color: #FFFFFF!important;
  color: #000000;
  border-radius: 5px;
  width: 426px;
  height: 25px;
  text-align: left;
  background: url("images/ic_perm_identity_24px.svg") no-repeat scroll 2px 2px;
  padding-left: 25px;
  background-size: 20px 20px;
}

.passwordInput {
  background-color: #FFFFFF!important;
  color: #000000;
  border-radius: 5px;
  width: 426px;
  height: 25px;
  text-align: left;
  background: url("images/ic_lock_outline_24px.svg") no-repeat scroll 2px 2px;
  padding-left: 25px;
  background-size: 20px 20px;
}

.footer {
  text-align: left;
  padding-left: 25px;
  border-top-width: 1px;
  border-color: #000000;
  margin-top: 600px;
  padding-top: 10px;
}
